<template>
  <v-card>
    <v-card-title>
      <v-layout row wrap justify-space-between align-center>
        <span>Preview</span>
        <v-icon color="black" @click="$emit('close')">mdi-close</v-icon>
      </v-layout>
    </v-card-title>
    <v-card-text v-if="image">
      <v-layout justify-center align-center>
        <img :src="image" />
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: ['file', 'url'],
    data: () => ({
      image: null,
    }),
    created() {
      if (this.file) {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.image = reader.result;
        };
      } else if (this.url) {
        this.image = this.url;
      }
    },
  };
</script>

<style lang="scss" scoped>
  img {
    max-width: 100%;
    height: auto;
  }
</style>

